<template>
    <div class="CheckCreate" style="background-color: #f2f2f2; margin-top: 8px">
        <el-card>
            <el-page-header @back="goBack" />
        </el-card>
        <el-form
            @submit.native.prevent
            :inline="true"
            :model="form"
            :rules="rules"
            ref="createForm"
            label-width="80px"
            size="small"
        >
            <el-card style="margin-top: 8px">
                <el-row>
                    <ef-dept-type-and-dept-select @selectDeptType="setDeptType" @selectDept="setDept" />
                    <el-col :span="8">
                        <el-form-item label="创建人">
                            <el-input v-model="$store.state.session.name" readonly />
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="8">
                        <el-form-item label="查询条件">
                            <el-input placeholder="商品名称/条码/助记码/自编码" v-model="search" />
                        </el-form-item>
                    </el-col>
                    <!--                    <el-col :span="8">-->
                    <!--                        <el-form-item label="类目">-->
                    <!--                            <ef-category v-model="form.categoryCode" />-->
                    <!--                        </el-form-item>-->
                    <!--                    </el-col>-->
                    <!--                    <el-col :span="8">-->
                    <!--                        <el-form-item label="品牌">-->
                    <!--                            <ef-brand v-model="form.brandCode" />-->
                    <!--                        </el-form-item>-->
                    <!--                    </el-col>-->
                    <el-col :span="8">
                        <el-form-item label="创建日期">
                            <el-input readonly :value="createTime" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="盘点类型" prop="verifyFlag">
                            <el-select v-model="form.verifyFlag" placeholder="请选择">
                                <el-option
                                    v-for="item in options"
                                    :key="item.value"
                                    :label="item.label"
                                    :value="item.value"
                                >
                                </el-option>
                            </el-select>
                        </el-form-item>
                        <div v-if="form.verifyFlag === false" style="color: #f56c6c; font-size: 12px; line-height: 1">
                            非营业盘点不同步收银机离线数据，离线数据未同步会导致盈亏数量偏差。
                        </div>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="24">
                        <el-form-item label="备注-盘点单" label-width="100px">
                            <el-input
                                v-model="form.remark"
                                type="textarea"
                                style="width: 800px"
                                :maxlength="200"
                                :minlength="1"
                                placeholder="字数不超过200"
                                show-word-limit
                            />
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-card>
            <el-card style="margin-top: 8px; text-align: left">
                <el-button type="primary" :disabled="disableQueryAndAddButton" @click="queryAndAdd" size="small">
                    查询并添加
                </el-button>
                <el-button
                    type="primary"
                    :disabled="disableShowPickUpGoodsDialog"
                    @click="showPickUpGoods"
                    size="small"
                >
                    选择商品基本信息
                </el-button>
            </el-card>
            <el-card shadow="never" style="margin-top: 8px" align="left">
                <div style="height: 400px; width: 100%">
                    <el-table
                        id="checkCreateTable"
                        border
                        ref="checkCreateTable"
                        stripe
                        :data="form.detailParams"
                        size="mini"
                        max-height="340"
                    >
                        <el-table-column type="selection" width="55" align="center" />
                        <el-table-column label="序号" type="index" width="50" align="center" fixed="left" />
                        <el-table-column label="商品名称" width="180" fixed="left" :show-overflow-tooltip="true">
                            <template slot-scope="scope">
                                {{ skuByGoodsCode(scope.row.goodsCode).name }}
                            </template>
                        </el-table-column>
                        <el-table-column label="条码" width="200" :show-overflow-tooltip="true">
                            <template slot-scope="scope">
                                {{
                                    skuByGoodsCode(scope.row.goodsCode)
                                        .bars.map((d) => d)
                                        .join(' / ')
                                }}
                            </template>
                        </el-table-column>
                        <el-table-column label="商品类型" width="80">
                            <template slot-scope="scope">
                                {{ skuByGoodsCode(scope.row.goodsCode).type | skuTypeDesc }}
                            </template>
                        </el-table-column>
                        <el-table-column label="助记码" width="100">
                            <template slot-scope="scope">
                                {{ goodsByCode(scope.row.goodsCode).fastBar }}
                            </template>
                        </el-table-column>
                        <!--                        <el-table-column label="自编码" width="100">-->
                        <!--                            <template slot-scope="scope">-->
                        <!--                                {{ goodsByCode(scope.row.goodsCode).customBar }}-->
                        <!--                            </template>-->
                        <!--                        </el-table-column>-->
                        <el-table-column label="规格" width="120">
                            <template slot-scope="scope">
                                {{ skuByGoodsCode(scope.row.goodsCode).specs }}
                            </template>
                        </el-table-column>
                        <el-table-column label="类目" width="120">
                            <template slot-scope="scope">
                                {{ skuByGoodsCode(scope.row.goodsCode).category }}
                            </template>
                        </el-table-column>
                        <el-table-column label="单位" width="80">
                            <template slot-scope="scope">
                                {{ skuByGoodsCode(scope.row.goodsCode).unit }}
                            </template>
                        </el-table-column>
                        <el-table-column label="品牌" width="150" :show-overflow-tooltip="true">
                            <template slot-scope="scope">
                                {{ skuByGoodsCode(scope.row.goodsCode).brand }}
                            </template>
                        </el-table-column>
                        <el-table-column label="初盘快照数量" width="120" v-if="hasPrivilege('biz.stock.query')">
                            <template slot-scope="scope">
                                <el-form-item
                                    :prop="'detailParams.' + scope.$index + '.actualStock'"
                                    :rules="rules.counts"
                                >
                                    {{ scope.row.actualStock }}
                                </el-form-item>
                            </template>
                        </el-table-column>
                        <!--                        <el-table-column label="盘点数量" width="170">-->
                        <!--                            <template slot-scope="scope">-->
                        <!--                                <el-form-item :prop="'detailParams.' + scope.$index + '.counts'" :rules="rules.counts">-->
                        <!--                                    <ef-stock-biz-count-input-->
                        <!--                                        v-model="scope.row.counts"-->
                        <!--                                        :sku-type="skuByGoodsCode(scope.row.goodsCode).type"-->
                        <!--                                        :min="1"-->
                        <!--                                    />-->
                        <!--                                </el-form-item>-->
                        <!--                            </template>-->
                        <!--                        </el-table-column>-->
                        <el-table-column label="操作" width="100">
                            <template slot-scope="scope">
                                <el-button size="mini" type="danger" @click="handleDelete(scope.$index)"
                                    >删除</el-button
                                >
                            </template>
                        </el-table-column>
                    </el-table>
                    <div class="btnBox">
                        <el-button
                            :loading="saveLoading"
                            :disabled="disableSaveButton"
                            @click="handleSave(true)"
                            size="medium"
                            >暂存</el-button
                        >
                        <el-button
                            type="primary"
                            :loading="saveLoading"
                            :disabled="disableSaveButton"
                            @click="handleSave(false)"
                            size="medium"
                            >提交</el-button
                        >
                    </div>
                </div>
            </el-card>
            <pick-up-goods
                ref="pickupGoods"
                :categoryCode="form.categoryCode"
                :brandCode="form.brandCode"
                :selfCanClose="false"
                @queryGoods="setSelectedGoods"
            />
            <!--            <div v-for="(item, idx) of form.detailParams" hidden :key="item.goodsCode">-->
            <!--                <el-form-item :prop="'detailParams.' + idx + '.snapshotCounts'" :rules="rules.counts">-->
            <!--                    {{ item.snapshotCounts }}-->
            <!--                </el-form-item>-->
            <!--                <el-form-item :prop="'detailParams.' + idx + '.counts'" :rules="rules.counts">-->
            <!--                    {{ item.counts }}-->
            <!--                </el-form-item>-->
            <!--            </div>-->
        </el-form>
    </div>
</template>

<script>
import Util from 'js/Util';
import StockBizCommon, { goodsStockProperty, PropertyConverter } from 'js/StockBizCommon';
import EfDeptTypeAndDeptSelect from 'components/EfDeptTypeAndDeptSelect';
import PickUpGoods from 'components/PickUpGoods';
import GoodsCommon from 'js/GoodsCommon';

export default {
    name: 'CheckCreate',
    components: { EfDeptTypeAndDeptSelect, PickUpGoods },
    data() {
        return {
            detailParamPropertyArr: [
                'codes',
                'goodsCode',
                'counts',
                new PropertyConverter(goodsStockProperty, 'snapshotCounts'),
            ],
            goodsDetailMap: new Map(),
            createTime: Util.nowFormatDate(),
            search: '',
            form: {
                detailParams: [],
                deptCode: '',
                deptName: '',
                remark: '',
                categoryCode: '',
                brandCode: '',
                verifyFlag: '',
            },
            options: [
                {
                    value: true,
                    label: '营业盘点',
                },
                {
                    value: false,
                    label: '非营业盘点',
                },
            ],
            rules: {
                verifyFlag: [{ required: true, message: '请选择盘点类型', trigger: 'change' }],
                //     deptCode: deptCodeValidateRule(),
                //     remark: remarkNotRequiredValidateRule(),
                //     counts: countValidateRule(
                //         () => (this.form || {}).detailParams || [],
                //         (goodsCode) => this.skuByGoodsCode(goodsCode)
                //     ),
            },
            saveLoading: false,
            actualStock: [],
        };
    },
    mounted() {},
    filters: {
        skuTypeDesc(type) {
            return GoodsCommon.skuTypeDesc(type);
        },
    },
    computed: {
        //禁用/启用按钮
        disableQueryAndAddButton() {
            return Util.isEmpty(this.form.deptCode) || Util.isEmpty(this.search);
        },
        //禁用/启用按钮
        disableShowPickUpGoodsDialog() {
            return Util.isEmpty(this.form.deptCode);
        },
        //禁用/启用按钮
        disableSaveButton() {
            return (
                Util.isEmpty(this.form.deptCode) ||
                this.form.detailParams == null ||
                this.form.detailParams.length === 0
            );
        },
    },
    watch: {
        'form.deptCode': function (newDeptCode, oldDeptCode) {
            //清空商品列表数据
            this.form.detailParams = [];
        },
    },
    methods: {
        goodsByCode(goodsCode) {
            return this.goodsDetailMap.get(goodsCode);
        },
        skuByGoodsCode(goodsCode) {
            return this.goodsByCode(goodsCode).sku;
        },
        setSelectedGoods(selectedGoods) {
            // console.log("selectedGoods",selectedGoods.actualStock)
            const oldDetails = Object.assign([], this.form.detailParams || []);
            // console.log('打印一下oldDetails',oldDetails)

            StockBizCommon.addPickupGoodsToStockBizDetails(
                selectedGoods,
                this.goodsDetailMap,
                oldDetails,
                this.detailParamPropertyArr
            );
            var aa = [];
            selectedGoods.forEach((item) => {
                aa.unshift(item.actualStock);
            });
            this.actualStock.unshift(aa);
            this.actualStock = this.actualStock.reduce(function (a, b) {
                return a.concat(b);
            });
            for (let i = 0; i < oldDetails.length; i++) {
                oldDetails[i].actualStock = this.actualStock[i];
            }
            if (oldDetails.length <= 150) {
                this.form.detailParams = oldDetails;
            } else {
                this.$message.warning('每笔单据做多添加150个商品，如想添加更多商品，请拆分为多条单据');
            }
        },
        setDept(deptCode, deptName) {
            this.form.deptCode = deptCode;
            this.form.deptName = deptName;
        },
        setDeptType(deptType) {
            this.deptType = deptType;
        },
        handleDelete(index) {
            this.form.detailParams.splice(index, 1);
            this.actualStock.splice(index, index + 1);
        },
        showPickUpGoods() {
            this.$refs.pickupGoods.show(this.pickUpGoodsQueryParam(), this.form.deptName);
        },
        async queryAndAdd() {
            const rst = await this.$efApi.goodsApi.queryGoods({ ...this.pickUpGoodsQueryParam(), search: this.search });
            this.setSelectedGoods(rst.data);
        },
        pickUpGoodsQueryParam() {
            return { deptCode: this.form.deptCode };
        },
        handleSave(type) {
            this.$refs.createForm.validate(async (valid) => {
                if (!valid) {
                    this.$message.error('填写内容有误，请检查所填写的所有数据');
                    return false;
                }
                if (this.form.detailParams.length > 150) {
                    this.$message.error('每笔单据做多添加150个商品，如想添加更多商品，请拆分为多条单据');
                    return false;
                }
                this.saveLoading = true;
                const params = {
                    deptCode: this.form.deptCode,
                    goodsCodes: this.form.detailParams.map((i) => i.goodsCode),
                    remark: this.form.remark,
                    verifyFlag: this.form.verifyFlag,
                };
                const timeout = '120000';
                if (type) {
                    // 暂存
                    await this.$efApi.stockCheckApi.temporaryStorage(params).finally(() => {
                        this.saveLoading = false;
                    });
                    this.goBackAndReload();
                } else {
                    // 提交
                    await this.$efApi.stockCheckApi.newlyBuildSubmit(params, timeout).finally(() => {
                        this.saveLoading = false;
                    });
                    this.goBackAndReload();
                }
            });
        },
    },
};
</script>
<style scoped>
.btnBox {
    margin-top: 20px;
}
.el-form-item__error {
    top: 100%;
    left: 131px;
}
</style>
